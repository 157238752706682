export async function handleLinkClick(event: MouseEvent): Promise<void> {
  let target = event.target;

  while (target !== null && target !== document) {
    if (target instanceof HTMLElement === false) {
      break;
    }

    if (target instanceof HTMLAnchorElement) {
      sendMatomoEvent({ linkType: "link", sourceUrl: target.href, type: MatomoEventType.trackLink });

      if (isExternalLink(target.href)) {
        window.open(target.href, "_blank", "noreferrer");
        break;
      }

      // external needs to be set because navigateTo does not work otherwise, even for relative links
      await navigateTo(target.href, { external: true });
      break;
    }

    target = target.parentNode;
  }
}
